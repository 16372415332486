.dimension-card-parent {
  width: 20% !important;
}
@media (max-width:747px) {
  .dimension-card-parent {
    width: 25% !important;
  }
}
@media (max-width:425px) {
  .dimension-card-parent {
    width: 30% !important;
  }
}
@media (max-width:320px) {
  .dimension-card-parent {
    width: 50% !important;
  }
}
.dimensions-card {
  background: white;
  border-radius: 5px;
  margin: 3px;
  cursor: pointer;
  //   box-shadow: rgba(255, 253, 253, 0.502) 0px 5px 15px;
  box-shadow: rgba(133, 134, 137, 0.705) 0px 0px 0.25em,
    rgba(133, 134, 137, 0.705) 0px 0.25em 1em;
  .title {
    color: black;
    font-weight: bold;
    font-size: 12px;
  }
  .dimensions {
    color: #404b51;
    font-size: 11px;
  }
  img {
    // width: 30% !important;
    // width: 43px !important;
    // height: 46px !important;
    width: 35px !important;
    height: 38px !important;
  }

  &:hover {
    background: #00b7ae !important;

    cursor: pointer;
    .title {
      color: white !important;
    }
    .dimensions {
      color: white !important;
    }
  }
}
@media (max-width:1067px) {
  .dimensions-card {
    background: white;
    border-radius: 5px;
    height: 120px;
    margin: 3px;
    cursor: pointer;
    //   box-shadow: rgba(255, 253, 253, 0.502) 0px 5px 15px;
    box-shadow: rgba(133, 134, 137, 0.705) 0px 0px 0.25em,
      rgba(133, 134, 137, 0.705) 0px 0.25em 1em;
    .title {
      color: black;
      font-weight: bold;
      font-size: 12px;
    }
    .dimensions {
      color: #404b51;
      font-size: 11px;
    }
    img {
      // width: 30% !important;
      // width: 43px !important;
      // height: 46px !important;
      width: 35px !important;
      height: 38px !important;
    }
  
    &:hover {
      background: #00b7ae !important;
  
      cursor: pointer;
      .title {
        color: white !important;
      }
      .dimensions {
        color: white !important;
      }
    }
  }
  
}
@media (max-width:1024px) {
  .dimensions-card {
    background: white;
    border-radius: 5px;
    height: 120px;
    margin: 3px;
    cursor: pointer;
    //   box-shadow: rgba(255, 253, 253, 0.502) 0px 5px 15px;
    box-shadow: rgba(133, 134, 137, 0.705) 0px 0px 0.25em,
      rgba(133, 134, 137, 0.705) 0px 0.25em 1em;
    .title {
      color: black;
      font-weight: bold;
      font-size: 12px;
    }
    .dimensions {
      color: #404b51;
      font-size: 11px;
    }
    img {
      // width: 30% !important;
      // width: 43px !important;
      // height: 46px !important;
      width: 35px !important;
      height: 38px !important;
    }
  
    &:hover {
      background: #00b7ae !important;
  
      cursor: pointer;
      .title {
        color: white !important;
      }
      .dimensions {
        color: white !important;
      }
    }
  }
  
}
@media (max-width:786px) {
  .dimensions-card {
    background: white;
    border-radius: 5px;
    width: auto;
    height: 125px;
    margin: 3px;
    cursor: pointer;
    //   box-shadow: rgba(255, 253, 253, 0.502) 0px 5px 15px;
    box-shadow: rgba(133, 134, 137, 0.705) 0px 0px 0.25em,
      rgba(133, 134, 137, 0.705) 0px 0.25em 1em;
    .title {
      color: black;
      font-weight: bold;
      font-size: 10px;
    }
    .dimensions {
      color: #404b51;
      font-size: 10px;
    }
    img {
      // width: 30% !important;
      // width: 43px !important;
      // height: 46px !important;
      width: 35px !important;
      height: 38px !important;
    }
  
    &:hover {
      background: #00b7ae !important;
  
      cursor: pointer;
      .title {
        color: white !important;
      }
      .dimensions {
        color: white !important;
      }
    }
  }
  
}
@media (max-width:425px) {
  .dimensions-card {
    background: white;
    border-radius: 5px;
    width: auto;
    height: 125px;
    margin: 3px;
    cursor: pointer;
    //   box-shadow: rgba(255, 253, 253, 0.502) 0px 5px 15px;
    box-shadow: rgba(133, 134, 137, 0.705) 0px 0px 0.25em,
      rgba(133, 134, 137, 0.705) 0px 0.25em 1em;
    .title {
      color: black;
      font-weight: bold;
      font-size: 10px;
    }
    .dimensions {
      color: #404b51;
      font-size: 10px;
    }
    img {
      // width: 30% !important;
      // width: 43px !important;
      // height: 46px !important;
      width: 35px !important;
      height: 38px !important;
    }
  
    &:hover {
      background: #00b7ae !important;
  
      cursor: pointer;
      .title {
        color: white !important;
      }
      .dimensions {
        color: white !important;
      }
    }
  }
  
}
@media (max-width:375px) {
  .dimensions-card {
    background: white;
    border-radius: 5px;
    width: auto;
    height: 125px;
    margin: 3px;
    cursor: pointer;
    //   box-shadow: rgba(255, 253, 253, 0.502) 0px 5px 15px;
    box-shadow: rgba(133, 134, 137, 0.705) 0px 0px 0.25em,
      rgba(133, 134, 137, 0.705) 0px 0.25em 1em;
    .title {
      color: black;
      font-weight: bold;
      font-size: 10px;
    }
    .dimensions {
      color: #404b51;
      font-size: 9px;
    }
    img {
      // width: 30% !important;
      // width: 43px !important;
      // height: 46px !important;
      width: 35px !important;
      height: 38px !important;
    }
  
    &:hover {
      background: #00b7ae !important;
  
      cursor: pointer;
      .title {
        color: white !important;
      }
      .dimensions {
        color: white !important;
      }
    }
  }
  
}
@media (max-width:320px) {
  .dimensions-card {
    background: white;
    border-radius: 5px;
    width: auto;
    height: 123px;
    margin: 3px;
    cursor: pointer;
    //   box-shadow: rgba(255, 253, 253, 0.502) 0px 5px 15px;
    box-shadow: rgba(133, 134, 137, 0.705) 0px 0px 0.25em,
      rgba(133, 134, 137, 0.705) 0px 0.25em 1em;
    .title {
      color: black;
      font-weight: bold;
      font-size: 10px;
    }
    .dimensions {
      color: #404b51;
      font-size: 9px;
    }
    img {
      // width: 30% !important;
      // width: 43px !important;
      // height: 46px !important;
      width: 35px !important;
      height: 38px !important;
    }
  
    &:hover {
      background: #00b7ae !important;
  
      cursor: pointer;
      .title {
        color: white !important;
      }
      .dimensions {
        color: white !important;
      }
    }
  }
  
}
@media (max-width:280px) {
  .dimensions-card {
    background: white;
    border-radius: 5px;
    width: auto;
    height: 123px;
    margin: 3px;
    cursor: pointer;
    //   box-shadow: rgba(255, 253, 253, 0.502) 0px 5px 15px;
    box-shadow: rgba(133, 134, 137, 0.705) 0px 0px 0.25em,
      rgba(133, 134, 137, 0.705) 0px 0.25em 1em;
    .title {
      color: black;
      font-weight: bold;
      font-size: 12px;
    }
    .dimensions {
      color: #404b51;
      font-size: 11px;
    }
    img {
      // width: 30% !important;
      // width: 43px !important;
      // height: 46px !important;
      width: 35px !important;
      height: 38px !important;
    }
  
    &:hover {
      background: #00b7ae !important;
  
      cursor: pointer;
      .title {
        color: white !important;
      }
      .dimensions {
        color: white !important;
      }
    }
  }
  
}
.dimensions-card-active {
  background: #00b7ae;
  border-radius: 5px;
  margin: 3px;
  box-shadow: rgba(255, 253, 253, 0.502) 0px 5px 15px;
  cursor: pointer;
  .title {
    color: white;
  }
  .dimensions {
    color: white;
  }
  img {
    width: 30% !important;
  }
}
@media (max-width:375px) {
  .kdpuIg label{
    font-size: 0.7rem !important;
  }
}
@media (max-width:320px) {
  .kdpuIg label{
    font-size: 0.5rem !important;
  }
}