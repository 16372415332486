.service-header {
  &__item {
    width: 100px !important;
    height: 70px !important;
    background: #404b51;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #8c9397;

    cursor: pointer;
    img {
      width: 70% !important;
    }
    &:hover {
      background: #00b7ae !important;
    }
  }

  &__item-active {
    // border: 2px solid #22c0b9 !important;
    background: #00b7ae !important;
  }

  &__card {
    border-radius: 10px !important;
    padding: 5px !important;
    background: white !important;
    padding: 10px 15px !important;
    width: 407px !important;

    p {
      text-align: start !important;
      font-weight: 600 !important;
      font-size: 20px;
    }
    &-label {
      // color: #000000 !important;
      // font-family: "Roboto";
      color: white !important;
      font-size: 18px !important;
    }
    &-value {
      color: #22c0b9 !important;
      font-size: 18px !important;
    }
  }

  &__hr-90 {
    border-top: 1px solid white !important;
    width: 90%;
  }
  &__hr-100 {
    border-top: 0px solid white !important;
    width: 100% !important;
    margin: 2rem 0 !important;
    color: white !important;
    background-color: white !important;
    border: 0px solid white !important;
    opacity: 1 !important;
  }

  &__wrapper {
    // width: 90% !important;
    width: 100% !important;
    background: white !important;

    display: flex;
    align-items: center;
    &-hr {
      height: 1.5px;
      background: white !important;
      width: 4% !important ;
    }
    &-item {
      background: white !important;
      // width: 22% !important;
      width: 25% !important;
      // border-radius: 10px;
      // padding: 10px 15px !important;
      // margin: 10px 15px !important;
      padding: 0px 15px !important;
      margin: 10px 0px !important;
      cursor: pointer;
    }

    .border-right {
      // border-right: 2px solid #404b51 !important;
      border-right: 2px solid #a7a7a7 !important;
    }

    .active-item {
      color: #d598a0 !important;
      .heading {
        // color: #404b51 !important;
        font-size: 18px !important;
        font-weight: bold;
      }
      .sub-heading {
        color: #d598a0 !important;

        // color: #404b51 !important;
        font-size: 14px !important;
        font-weight: bold;
        cursor: pointer;
      }
    }

    .inactive-item {
      .heading {
        font-size: 18px;
        font-weight: bold;
        color: #00b7ae;
      }
      .sub-heading {
        color: #00b7ae;
        font-size: 14px;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }
  @media (max-width: 287px) {
    &__wrapper {
      width: 107% !important;
    }
  }

  .sd-button-active {
    // background: hsl(340deg 100% 32%);
    background: #00b7aeb0;
    border-radius: 12px;
    border: none;
    padding: 0;
    cursor: pointer;
    outline-offset: 4px;
    .sd-button-front-truckship {
      display: block;
      padding: 5px 25px;
      border-radius: 12px;
      font-size: 1.25rem;
      // background: hsl(345deg 100% 47%);
      background: #00b7ae !important;
      color: white;
      transform: translateY(-6px);
      img {
        width: 125px !important;
        height: 3.5rem !important;
      }
    }
    .sd-button-front {
      display: block;
      padding: 12px 42px;
      border-radius: 12px;
      font-size: 1.25rem;
      // background: hsl(345deg 100% 47%);
      background: #00b7ae !important;
      color: white;
      transform: translateY(-6px);
      img {
        width: 70px !important;
        height: 41px !important;
      }
    }
  }
  @media (max-width: 767px) {
    .sd-button-active {
      // background: hsl(340deg 100% 32%);
      background: #00b7aeb0;
      border-radius: 12px;
      border: none;
      padding: 0;
      width: 104%;
      cursor: pointer;
      outline-offset: 4px;
      .sd-button-front-truckship {
        display: block;
        padding: 5px 25px;
        border-radius: 12px;
        font-size: 1.25rem;
        // background: hsl(345deg 100% 47%);
        background: #00b7ae !important;
        color: white;
        transform: translateY(-6px);
        img {
          width: 125px !important;
          height: 3.5rem !important;
        }
      }
      .sd-button-front {
        display: block;
        padding: 12px 42px;
        border-radius: 12px;
        font-size: 1.25rem;
        // background: hsl(345deg 100% 47%);
        background: #00b7ae !important;
        color: white;
        transform: translateY(-6px);
        img {
          width: 70px !important;
          height: 41px !important;
        }
      }
    }
  }
  .sd-button {
    background: #00b7aeb0;
    border-radius: 12px;
    border: none;
    padding: 0;
    cursor: pointer;
    outline-offset: 4px;
    .sd-button-front-truckship {
      display: block;
      padding: 5px 25px;
      border-radius: 12px;
      font-size: 1.25rem;
      border: 1px solid #00b7ae !important;
      // background: hsl(345deg 100% 47%);
      background: #404b51 !important;

      &:hover {
        background: #00b7ae !important;
      }
      color: white;
      transform: translateY(-6px);
      img {
        width: 125px !important;
        height: 3.5rem !important;
      }
    }
    .sd-button-front {
      display: block;
      padding: 12px 42px;
      border-radius: 12px;
      font-size: 1.25rem;
      border: 1px solid #00b7ae !important;
      // background: hsl(345deg 100% 47%);
      background: #404b51 !important;

      &:hover {
        background: #00b7ae !important;
      }
      color: white;
      transform: translateY(-6px);
      img {
        width: 70px !important;
        height: 41px !important;
      }
    }
  }
  @media (max-width: 767px) {
    .sd-button {
      background: #00b7aeb0;
      border-radius: 12px;
      border: none;
      padding: 0;
      width: 104%;
      cursor: pointer;
      outline-offset: 4px;
      .sd-button-front-truckship {
        display: block;
        padding: 5px 25px;
        border-radius: 12px;
        font-size: 1.25rem;
        border: 1px solid #00b7ae !important;
        // background: hsl(345deg 100% 47%);
        background: #404b51 !important;

        &:hover {
          background: #00b7ae !important;
        }
        color: white;
        transform: translateY(-6px);
        img {
          width: 125px !important;
          height: 3.5rem !important;
        }
      }
      .sd-button-front {
        display: block;
        padding: 12px 42px;
        border-radius: 12px;
        font-size: 1.25rem;
        border: 1px solid #00b7ae !important;
        // background: hsl(345deg 100% 47%);
        background: #404b51 !important;

        &:hover {
          background: #00b7ae !important;
        }
        color: white;
        transform: translateY(-6px);
        img {
          width: 70px !important;
          height: 41px !important;
        }
      }
    }
  }
  .sd-button-selected {
    background: #00b7ae;
    border-radius: 12px;
    border: none;
    padding: 0;
    outline-offset: 4px;
    font-size: 23px;
  }
  @media (max-width: 1042px) {
    .sd-button-selected {
      background: #00b7ae;
      border-radius: 12px;
      border: none;
      padding: 0 10px;
      outline-offset: 4px;
      font-size: 23px;
      white-space: nowrap;
    }
  }
  @media (max-width: 870px) {
    .sd-button-selected {
      background: #00b7ae;
      border-radius: 12px;
      border: none;
      padding: 0 10px;
      outline-offset: 4px;
      font-size: 20px;
      white-space: nowrap;
    }
  }
  @media (max-width: 790px) {
    .sd-button-selected {
      background: #00b7ae;
      border-radius: 12px;
      border: none;
      padding: 0 10px;
      outline-offset: 4px;
      font-size: 19px;
      white-space: nowrap;
    }
  }
  @media (max-width: 430px) {
    .sd-button-selected {
      background: #00b7ae;
      border-radius: 12px;
      border: none;
      padding: 0 10px;
      outline-offset: 4px;
      font-size: 18px;
      white-space: nowrap;
    }
  }
  @media (max-width: 412px) {
    .sd-button-selected {
      background: #00b7ae;
      border-radius: 12px;
      border: none;
      padding: 0 10px;
      outline-offset: 4px;
      font-size: 18px;
      white-space: nowrap;
    }
  }
  @media (max-width: 389px) {
    .sd-button-selected {
      background: #00b7ae;
      border-radius: 12px;
      border: none;
      padding: 0 10px;
      outline-offset: 4px;
      font-size: 17px;
      white-space: nowrap;
    }
  }
  @media (max-width: 376px) {
    .sd-button-selected {
      background: #00b7ae;
      border-radius: 12px;
      border: none;
      padding: 0 10px;
      outline-offset: 4px;
      font-size: 16px;
      white-space: nowrap;
    }
  }
  @media (max-width: 364px) {
    .sd-button-selected {
      background: #00b7ae;
      border-radius: 12px;
      border: none;
      padding: 0 10px;
      outline-offset: 4px;
      font-size: 15px;
      white-space: nowrap;
    }
  }
  @media (max-width: 351px) {
    .sd-button-selected {
      background: #00b7ae;
      border-radius: 12px;
      border: none;
      padding: 0 10px;
      outline-offset: 4px;
      font-size: 14px;
      white-space: nowrap;
    }
  }
  @media (max-width: 340px) {
    .sd-button-selected {
      background: #00b7ae;
      border-radius: 12px;
      border: none;
      padding: 0 10px;
      outline-offset: 4px;
      font-size: 12px;
      white-space: nowrap;
    }
  }
  @media (max-width: 320px) {
    .sd-button-selected {
      background: #00b7ae;
      border-radius: 12px;
      border: none;
      padding: 0 10px;
      outline-offset: 4px;
      font-size: 12px;
      white-space: nowrap;
    }
  }
  @media (max-width: 319px) {
    .sd-button-selected {
      background: #00b7ae;
      border-radius: 12px;
      border: none;
      padding: 0 10px;
      outline-offset: 4px;
      font-size: 12px;
      white-space: nowrap;
    }
  }
  @media (max-width: 310px) {
    .sd-button-selected {
      background: #00b7ae;
      border-radius: 12px;
      border: none;
      padding: 0 10px;
      outline-offset: 4px;
      font-size: 10px;
      white-space: nowrap;
    }
  }
  @media (max-width: 280px) {
    .sd-button-selected {
      background: #00b7ae;
      border-radius: 12px;
      border: none;
      padding: 0 10px;
      outline-offset: 4px;
      font-size: 9px;
      white-space: nowrap;
    }
  }
}

// @media (max-width:991px) {
//   .service-header .sd-button .sd-button-front {
//     display: block;
//     padding: 12px 328px;
//     border-radius: 12px;
//     font-size: 1.25rem;
//     border: 1px solid #00b7ae !important;
//     background: #404b51 !important;
//     color: white;
//     transform: translateY(-6px);
//     margin-bottom: 5px;
//   }
// }
// @media (max-width:957px) {
//   .service-header .sd-button .sd-button-front {
//     display: block;
//     padding: 12px 260px;
//     border-radius: 12px;
//     font-size: 1.25rem;
//     border: 1px solid #00b7ae !important;
//     background: #404b51 !important;
//     color: white;
//     transform: translateY(-6px);
//     margin-bottom: 5px;
//   }
// }
@media (max-width: 1036px) {
  .service-header .sd-button .sd-button-front {
    display: block;
    padding: 12px 15px;
    border-radius: 12px;
    font-size: 1.25rem;
    border: 1px solid #00b7ae !important;
    background: #404b51 !important;
    color: white;
    transform: translateY(-6px);
    margin-bottom: 0px !important;
  }
}
@media (max-width: 820px) {
  .service-header .sd-button .sd-button-front {
    display: block;
    padding: 9px 9px;
    border-radius: 12px;
    font-size: 1.25rem;
    border: 1px solid #00b7ae !important;
    background: #404b51 !important;
    color: white;
    transform: translateY(-6px);
    margin-bottom: 0px;
  }
}
@media (max-width: 760px) {
  .service-header .sd-button .sd-button-front {
    display: block;
    padding: 12px 200px;
    border-radius: 12px;
    font-size: 1.25rem;
    border: 1px solid #00b7ae !important;
    background: #404b51 !important;
    color: white;
    transform: translateY(-6px);
    margin-bottom: 5px;
  }
}
@media (max-width: 600px) {
  .service-header .sd-button .sd-button-front {
    display: block;
    padding: 12px 100px;
    border-radius: 12px;
    font-size: 1.25rem;
    border: 1px solid #00b7ae !important;
    background: #404b51 !important;
    color: white;
    transform: translateY(-6px);
    margin-bottom: 5px;
  }
}
@media (max-width: 540px) {
  .service-header .sd-button .sd-button-front {
    display: block;
    padding: 12px 100px;
    border-radius: 12px;
    font-size: 1.25rem;
    border: 1px solid #00b7ae !important;
    background: #404b51 !important;
    color: white;
    transform: translateY(-6px);
    margin-bottom: 5px;
  }
}
@media (max-width: 430px) {
  .service-header .sd-button .sd-button-front {
    display: block;
    padding: 12px 95px;
    border-radius: 12px;
    font-size: 1.25rem;
    border: 1px solid #00b7ae !important;
    background: #404b51 !important;
    color: white;
    transform: translateY(-6px);
    margin-bottom: 5px;
  }
}
@media (max-width: 390px) {
  .service-header .sd-button .sd-button-front {
    display: block;
    padding: 12px 87px;
    border-radius: 12px;
    font-size: 1.25rem;
    border: 1px solid #00b7ae !important;
    background: #404b51 !important;
    color: white;
    transform: translateY(-6px);
    margin-bottom: 5px;
  }
}
@media (max-width: 375px) {
  .service-header .sd-button .sd-button-front {
    display: block;
    padding: 12px 79px;
    border-radius: 12px;
    font-size: 1.25rem;
    border: 1px solid #00b7ae !important;
    background: #404b51 !important;
    color: white;
    transform: translateY(-6px);
    margin-bottom: 5px;
  }
}
@media (max-width: 344px) {
  .service-header .sd-button .sd-button-front {
    display: block;
    padding: 12px 65px;
    border-radius: 12px;
    font-size: 1.25rem;
    border: 1px solid #00b7ae !important;
    background: #404b51 !important;
    color: white;
    transform: translateY(-6px);
    margin-bottom: 5px;
  }
}
@media (max-width: 320px) {
  .service-header .sd-button .sd-button-front {
    display: block;
    padding: 12px 60px;
    border-radius: 12px;
    font-size: 1.25rem;
    border: 1px solid #00b7ae !important;
    background: #404b51 !important;
    color: white;
    transform: translateY(-6px);
    margin-bottom: 5px;
  }
}
// @media (max-width:991px) {
// .service-header .sd-button-active .sd-button-front {
//     display: block;
//     padding: 12px 328px;
//     border-radius: 12px;
//     font-size: 1.25rem;
//     background: #00b7ae !important;
//     color: white;
//     transform: translateY(-6px);
// }
// }
// @media (max-width:957px) {
// .service-header .sd-button-active .sd-button-front {
//     display: block;
//     padding: 12px 260px;
//     border-radius: 12px;
//     font-size: 1.25rem;
//     background: #00b7ae !important;
//     color: white;
//     transform: translateY(-6px);
// }
// }
@media (max-width: 1036px) {
  .service-header .sd-button-active .sd-button-front {
    display: block;
    padding: 12px 15px;
    border-radius: 12px;
    font-size: 1.25rem;
    background: #00b7ae !important;
    color: white;
    transform: translateY(-6px);
  }
}
@media (max-width: 820px) {
  .service-header .sd-button-active .sd-button-front {
    display: block;
    padding: 9px 9px;
    border-radius: 12px;
    font-size: 1.25rem;
    background: #00b7ae !important;
    color: white;
    transform: translateY(-6px);
  }
}
@media (max-width: 760px) {
  .service-header .sd-button-active .sd-button-front {
    display: block;
    padding: 12px 200px;
    border-radius: 12px;
    font-size: 1.25rem;
    background: #00b7ae !important;
    color: white;
    transform: translateY(-6px);
  }
}
@media (max-width: 600px) {
  .service-header .sd-button-active .sd-button-front {
    display: block;
    padding: 12px 100px;
    border-radius: 12px;
    font-size: 1.25rem;
    background: #00b7ae !important;
    color: white;
    transform: translateY(-6px);
  }
}
@media (max-width: 540px) {
  .service-header .sd-button-active .sd-button-front {
    display: block;
    padding: 12px 100px;
    border-radius: 12px;
    font-size: 1.25rem;
    background: #00b7ae !important;
    color: white;
    transform: translateY(-6px);
  }
}
@media (max-width: 430px) {
  .service-header .sd-button-active .sd-button-front {
    display: block;
    padding: 12px 95px;
    border-radius: 12px;
    font-size: 1.25rem;
    background: #00b7ae !important;
    color: white;
    transform: translateY(-6px);
  }
}
@media (max-width: 390px) {
  .service-header .sd-button-active .sd-button-front {
    display: block;
    padding: 12px 87px;
    border-radius: 12px;
    font-size: 1.25rem;
    background: #00b7ae !important;
    color: white;
    transform: translateY(-6px);
  }
}
@media (max-width: 375px) {
  .service-header .sd-button-active .sd-button-front {
    display: block;
    padding: 12px 79px;
    border-radius: 12px;
    font-size: 1.25rem;
    background: #00b7ae !important;
    color: white;
    transform: translateY(-6px);
  }
}
@media (max-width: 344px) {
  .service-header .sd-button-active .sd-button-front {
    display: block;
    padding: 12px 65px;
    border-radius: 12px;
    font-size: 1.25rem;
    background: #00b7ae !important;
    color: white;
    transform: translateY(-6px);
  }
}
@media (max-width: 320px) {
  .service-header .sd-button-active .sd-button-front {
    display: block;
    padding: 12px 60px;
    border-radius: 12px;
    font-size: 1.25rem;
    background: #00b7ae !important;
    color: white;
    transform: translateY(-6px);
  }
}
@media (max-width: 991px) {
  .service-header .sd-button {
    background: rgba(0, 183, 174, 0.6901960784);
    border-radius: 12px;
    border: none;
    padding: 0;
    cursor: pointer;
    outline-offset: 4px;
    margin-bottom: 5px;
    margin-top: 5px;
  }
}
@media (max-width: 991px) {
  .service-header .sd-button-active {
    background: rgba(0, 183, 174, 0.6901960784);
    border-radius: 12px;
    border: none;
    padding: 0;
    cursor: pointer;
    outline-offset: 4px;
    margin-bottom: 5px;
    margin-top: 5px;
  }
}

.portugal_header-item {
  width: 50% !important;
}
