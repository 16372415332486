.sea-freight {
  border-radius: 40px;
  border: 1px solid #404b51 !important;
  background: #404b51;
  max-width: 1100px !important;
  min-height: 500px !important;
  background: #404b51 !important;
  padding: 20px 20px 50px 20px !important;
  margin-bottom: 40px !important;
  .custom-input-with-dropdown {
    font-size: 12px !important;
    position: relative;
    .input {
      display: block;
      width: 100%;
      height: calc(1.5em + 0.75rem + 2px);
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      padding-right: 10px !important;

      &__text {
        white-space: nowrap;
        text-overflow: ellipsis !important;
        width: 70% !important;
        display: flex;
        overflow: hidden;
        font-size: 12px !important;
      }

      .fa {
        position: absolute;
        right: 20px !important;
      }
    }
    ul {
      list-style: none;
      background: white;
      padding: 0px !important;
      width: 100%;
      position: absolute;
      z-index: 1;
      border-radius: 6px !important;
      top: 41px;
    }
    li {
      display: flex;
      padding: 10px !important;
      justify-content: start;
      background: white !important;
      align-items: center;
      cursor: pointer;
      border-radius: 6px !important;
      img {
        width: 15px !important;
        height: 20px !important;
      }
    }
    img {
      width: 15px !important;
      height: 20px !important;
    }
  }

  .text-transform-none {
    text-transform: none !important;
  }

  .input-group-text {
    border: none !important;
    font-size: 10px;

    padding-left: 10px;
    padding-right: 10px;
  }

  &__input {
    width: auto !important;
  }

  &__label {
    color: white;
    font-weight: 600 !important;
    white-space: nowrap;
  }

  .toggle-switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 25px;
    margin-bottom: 0px !important;
  }
  .toggle-switch input[type="checkbox"] {
    display: none;
  }
  .toggle-switch .switch {
    position: absolute;
    cursor: pointer;
    background-color: #ccc;
    border-radius: 25px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background-color 0.2s ease;
  }
  .toggle-switch .switch::before {
    position: absolute;
    content: "";
    // left: 2px;
    // top: 2px;
    // width: 21px;
    // height: 21px;
    // background-color: #aaa;
    left: 4px;
    top: 5px;
    width: 15px;
    height: 15px;
    background-color: white;
    border-radius: 50%;
    transition: transform 0.3s ease;
  }
  .toggle-switch input[type="checkbox"]:checked + .switch::before {
    transform: translateX(25px);
    // background-color: #6699cc;
    background-color: white !important;
  }
  .toggle-switch input[type="checkbox"]:checked + .switch {
    // background-color: #336699;
    background-color: #00b7ae;
  }

  &__total-shipment {
    display: flex;
    flex-direction: column;
    align-items: center;
    hr {
      border-top: 1px solid white;
      width: 30% !important;
    }
    &-card {
      width: 60% !important;
      background: white !important;
      border-radius: 12px;
      padding: 10px 15px;
    }
  }

  //Custom plus minus

  .custom-plus-minus {
    background: white !important;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    .form-control {
      background: none !important;
      border: none !important;
      width: 50px;
      text-align: center;
    }

    &__icon {
      // width: 20px;
      // height: 20px;
      border-radius: 50% !important;
      // border: 1px solid #00b7ae !important;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .fa {
        color: #00b7ae !important;
        font-size: 10px !important;
      }
    }
  }

  &__input-group {
    display: flex;
    flex-wrap: nowrap;
  }

  &__w-219 {
    width: 219px !important;
  }
  &__w-180 {
    width: 180px !important;
  }
  &__w-150 {
    width: 150px !important;
  }
  @media (max-width:900px) {
    &__w-150 {
      width: 120px !important;
    }
  }
  &__w-100 {
    width: 100px !important;
  }
  &__w-76 {
    width: 76px !important;
  }
  &__w-57 {
    width: 57px !important;
  }
  &__w-72 {
    width: 72px !important;
  }
  &__btn-group {
    height: 38px !important;
    margin-top: 2.5rem;
    .btn-minus {
      height: 17px !important;
      display: flex;
      align-items: center;
    }
  }

  .total-cbm {
    &__card {
      background: white !important;
      border-radius: 10px !important;
      color: #404b51;
      font-weight: 600;

      a {
        text-decoration: underline !important;
        &:hover {
          color: #00b7ae !important;
          cursor: pointer !important;
        }
      }
    }
    @media (max-width:377px) {
      &__card p,a{
        font-size: 14px;
      }
    }
    @media (max-width:365px) {
      &__card p,a{
        font-size: 13px;
      }
    }
    @media (max-width:310px) {
      &__card p,a{
        font-size: 12px;
      }
    }
    @media (max-width:575px) {
      &__card {
        width: 400px;
      }
    }
    @media (max-width:565px) {
      &__card {
        width: 390px;
      }
    }
    @media (max-width:555px) {
      &__card {
        width: 380px;
      }
    }
    @media (max-width:540px) {
      &__card {
        width: 370px;
      }
    }
    @media (max-width:530px) {
      &__card {
        width: 360px;
      }
    }
    @media (max-width:515px) {
      &__card {
        width: 350px;
      }
    }
    @media (max-width:500px) {
      &__card {
        width: 340px;
      }
    }
    @media (max-width:499px) {
      &__card {
        width: auto;
      }
    }
    @media (max-width:399px) {
      &__card {
        width: 230px;
      }
    }
    @media (max-width:377px) {
      &__card {
        width: 225px;
      }
    }
    @media (max-width:365px) {
      &__card {
        width: 220px;
      }
    }
    @media (max-width:364px) {
      &__card {
        width: auto;
      }
    }
  }

  &__international-relocation {
    border-right: 1px solid white !important;
  }
  @media (max-width:767px) {
    &__international-relocation {
      border-right: 0px !important;
    }
  }
}
@media (max-width:1280px) {
  .sea-freight{
    max-width: 0 !important;
    min-width: 1100px !important;
  }
}
@media (max-width:1279px) {
  .sea-freight{
    min-width: 0 !important;
    max-width: 1100px !important;
  }
}
@media (max-width:668px) {
  .sea-freight {
    border-radius: 40px;
    border: 1px solid #404b51 !important;
    background: #404b51;
    max-width: 600px !important;
    min-height: 500px !important;
    background: #404b51 !important;
    padding: 20px 20px 50px 20px !important;
    margin-bottom: 40px !important;
    .custom-input-with-dropdown {
      font-size: 12px !important;
      position: relative;
      .input {
        display: block;
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        padding-right: 10px !important;
  
        &__text {
          white-space: nowrap;
          text-overflow: ellipsis !important;
          width: 70% !important;
          display: flex;
          overflow: hidden;
          font-size: 12px !important;
        }
  
        .fa {
          position: absolute;
          right: 20px !important;
        }
      }
      ul {
        list-style: none;
        background: white;
        padding: 0px !important;
        width: 100%;
        position: absolute;
        z-index: 1;
        border-radius: 6px !important;
        top: 41px;
      }
      li {
        display: flex;
        padding: 10px !important;
        justify-content: start;
        background: white !important;
        align-items: center;
        cursor: pointer;
        border-radius: 6px !important;
        img {
          width: 15px !important;
          height: 20px !important;
        }
      }
      img {
        width: 15px !important;
        height: 20px !important;
      }
    }
  
    .text-transform-none {
      text-transform: none !important;
    }
  
    .input-group-text {
      border: none !important;
      font-size: 10px;
  
      padding-left: 10px;
      padding-right: 10px;
    }
  
    &__input {
      width: auto !important;
    }
  
    &__label {
      color: white;
      font-weight: 600 !important;
      white-space: nowrap;
    }
  
    .toggle-switch {
      position: relative;
      display: inline-block;
      width: 50px;
      height: 25px;
      margin-bottom: 0px !important;
    }
    .toggle-switch input[type="checkbox"] {
      display: none;
    }
    .toggle-switch .switch {
      position: absolute;
      cursor: pointer;
      background-color: #ccc;
      border-radius: 25px;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transition: background-color 0.2s ease;
    }
    .toggle-switch .switch::before {
      position: absolute;
      content: "";
      // left: 2px;
      // top: 2px;
      // width: 21px;
      // height: 21px;
      // background-color: #aaa;
      left: 4px;
      top: 5px;
      width: 15px;
      height: 15px;
      background-color: white;
      border-radius: 50%;
      transition: transform 0.3s ease;
    }
    .toggle-switch input[type="checkbox"]:checked + .switch::before {
      transform: translateX(25px);
      // background-color: #6699cc;
      background-color: white !important;
    }
    .toggle-switch input[type="checkbox"]:checked + .switch {
      // background-color: #336699;
      background-color: #00b7ae;
    }
  
    &__total-shipment {
      display: flex;
      flex-direction: column;
      align-items: center;
      hr {
        border-top: 1px solid white;
        width: 30% !important;
      }
      &-card {
        width: 60% !important;
        background: white !important;
        border-radius: 12px;
        padding: 10px 15px;
      }
    }
  
    //Custom plus minus
  
    .custom-plus-minus {
      background: white !important;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      .form-control {
        background: none !important;
        border: none !important;
        width: 50px;
        text-align: center;
      }
  
      &__icon {
        // width: 20px;
        // height: 20px;
        border-radius: 50% !important;
        // border: 1px solid #00b7ae !important;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .fa {
          color: #00b7ae !important;
          font-size: 10px !important;
        }
      }
    }
  
    &__input-group {
      display: flex;
      flex-wrap: nowrap;
    }
  
    &__w-219 {
      width: 219px !important;
    }
    &__w-180 {
      width: 180px !important;
    }
    &__w-150 {
      width: 150px !important;
    }
    &__w-100 {
      width: 100px !important;
    }
    &__w-76 {
      width: 76px !important;
    }
    &__w-57 {
      width: 57px !important;
    }
    &__w-72 {
      width: 72px !important;
    }
    &__btn-group {
      height: 38px !important;
      margin-top: 2.5rem;
      .btn-minus {
        height: 17px !important;
        display: flex;
        align-items: center;
      }
    }
  
    .total-cbm {
      &__card {
        background: white !important;
        border-radius: 10px !important;
        color: #404b51;
        font-weight: 600;
  
        a {
          text-decoration: underline !important;
          &:hover {
            color: #00b7ae !important;
            cursor: pointer !important;
          }
        }
      }
    }
  
    &__international-relocation {
      border-right: 1px solid white !important;
    }
    @media (max-width:767px) {
      &__international-relocation {
        border-right: 0px !important;
      }
    }
  }
}
@media (max-width:475px) {
  .sea-freight {
    border-radius: 40px;
    border: 1px solid #404b51 !important;
    background: #404b51;
    max-width: 400px !important;
    min-height: 500px !important;
    background: #404b51 !important;
    padding: 20px 20px 50px 20px !important;
    margin-bottom: 40px !important;
    .custom-input-with-dropdown {
      font-size: 12px !important;
      position: relative;
      .input {
        display: block;
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        padding-right: 10px !important;
  
        &__text {
          white-space: nowrap;
          text-overflow: ellipsis !important;
          width: 70% !important;
          display: flex;
          overflow: hidden;
          font-size: 12px !important;
        }
  
        .fa {
          position: absolute;
          right: 20px !important;
        }
      }
      ul {
        list-style: none;
        background: white;
        padding: 0px !important;
        width: 100%;
        position: absolute;
        z-index: 1;
        border-radius: 6px !important;
        top: 41px;
      }
      li {
        display: flex;
        padding: 10px !important;
        justify-content: start;
        background: white !important;
        align-items: center;
        cursor: pointer;
        border-radius: 6px !important;
        img {
          width: 15px !important;
          height: 20px !important;
        }
      }
      img {
        width: 15px !important;
        height: 20px !important;
      }
    }
  
    .text-transform-none {
      text-transform: none !important;
    }
  
    .input-group-text {
      border: none !important;
      font-size: 10px;
  
      padding-left: 10px;
      padding-right: 10px;
    }
  
    &__input {
      width: auto !important;
    }
  
    &__label {
      color: white;
      font-weight: 600 !important;
      white-space: nowrap;
    }
  
    .toggle-switch {
      position: relative;
      display: inline-block;
      width: 50px;
      height: 25px;
      margin-bottom: 0px !important;
    }
    .toggle-switch input[type="checkbox"] {
      display: none;
    }
    .toggle-switch .switch {
      position: absolute;
      cursor: pointer;
      background-color: #ccc;
      border-radius: 25px;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transition: background-color 0.2s ease;
    }
    .toggle-switch .switch::before {
      position: absolute;
      content: "";
      // left: 2px;
      // top: 2px;
      // width: 21px;
      // height: 21px;
      // background-color: #aaa;
      left: 4px;
      top: 5px;
      width: 15px;
      height: 15px;
      background-color: white;
      border-radius: 50%;
      transition: transform 0.3s ease;
    }
    .toggle-switch input[type="checkbox"]:checked + .switch::before {
      transform: translateX(25px);
      // background-color: #6699cc;
      background-color: white !important;
    }
    .toggle-switch input[type="checkbox"]:checked + .switch {
      // background-color: #336699;
      background-color: #00b7ae;
    }
  
    &__total-shipment {
      display: flex;
      flex-direction: column;
      align-items: center;
      hr {
        border-top: 1px solid white;
        width: 30% !important;
      }
      &-card {
        width: 60% !important;
        background: white !important;
        border-radius: 12px;
        padding: 10px 15px;
      }
    }
  
    //Custom plus minus
  
    .custom-plus-minus {
      background: white !important;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      .form-control {
        background: none !important;
        border: none !important;
        width: 50px;
        text-align: center;
      }
  
      &__icon {
        // width: 20px;
        // height: 20px;
        border-radius: 50% !important;
        // border: 1px solid #00b7ae !important;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .fa {
          color: #00b7ae !important;
          font-size: 10px !important;
        }
      }
    }
  
    &__input-group {
      display: flex;
      flex-wrap: nowrap;
    }
  
    &__w-219 {
      width: 219px !important;
    }
    &__w-180 {
      width: 180px !important;
    }
    &__w-150 {
      width: 150px !important;
    }
    &__w-100 {
      width: 100px !important;
    }
    &__w-76 {
      width: 76px !important;
    }
    &__w-57 {
      width: 57px !important;
    }
    &__w-72 {
      width: 72px !important;
    }
    &__btn-group {
      height: 38px !important;
      margin-top: 2.5rem;
      .btn-minus {
        height: 17px !important;
        display: flex;
        align-items: center;
      }
    }
  
    .total-cbm {
      &__card {
        background: white !important;
        border-radius: 10px !important;
        color: #404b51;
        font-weight: 600;
  
        a {
          text-decoration: underline !important;
          &:hover {
            color: #00b7ae !important;
            cursor: pointer !important;
          }
        }
      }
    }
  
    &__international-relocation {
      border-right: 1px solid white !important;
    }
    @media (max-width:767px) {
      &__international-relocation {
        border-right: 0px !important;
      }
    }
  }
}
@media (max-width:435px) {
  .sea-freight {
    border-radius: 40px;
    border: 1px solid #404b51 !important;
    background: #404b51;
    max-width: 380px !important;
    min-height: 500px !important;
    background: #404b51 !important;
    padding: 20px 20px 50px 20px !important;
    margin-bottom: 40px !important;
    .custom-input-with-dropdown {
      font-size: 12px !important;
      position: relative;
      .input {
        display: block;
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        padding-right: 10px !important;
  
        &__text {
          white-space: nowrap;
          text-overflow: ellipsis !important;
          width: 70% !important;
          display: flex;
          overflow: hidden;
          font-size: 12px !important;
        }
  
        .fa {
          position: absolute;
          right: 20px !important;
        }
      }
      ul {
        list-style: none;
        background: white;
        padding: 0px !important;
        width: 100%;
        position: absolute;
        z-index: 1;
        border-radius: 6px !important;
        top: 41px;
      }
      li {
        display: flex;
        padding: 10px !important;
        justify-content: start;
        background: white !important;
        align-items: center;
        cursor: pointer;
        border-radius: 6px !important;
        img {
          width: 15px !important;
          height: 20px !important;
        }
      }
      img {
        width: 15px !important;
        height: 20px !important;
      }
    }
  
    .text-transform-none {
      text-transform: none !important;
    }
  
    .input-group-text {
      border: none !important;
      font-size: 10px;
  
      padding-left: 10px;
      padding-right: 10px;
    }
  
    &__input {
      width: auto !important;
    }
  
    &__label {
      color: white;
      font-weight: 600 !important;
      white-space: nowrap;
    }
  
    .toggle-switch {
      position: relative;
      display: inline-block;
      width: 50px;
      height: 25px;
      margin-bottom: 0px !important;
    }
    .toggle-switch input[type="checkbox"] {
      display: none;
    }
    .toggle-switch .switch {
      position: absolute;
      cursor: pointer;
      background-color: #ccc;
      border-radius: 25px;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transition: background-color 0.2s ease;
    }
    .toggle-switch .switch::before {
      position: absolute;
      content: "";
      // left: 2px;
      // top: 2px;
      // width: 21px;
      // height: 21px;
      // background-color: #aaa;
      left: 4px;
      top: 5px;
      width: 15px;
      height: 15px;
      background-color: white;
      border-radius: 50%;
      transition: transform 0.3s ease;
    }
    .toggle-switch input[type="checkbox"]:checked + .switch::before {
      transform: translateX(25px);
      // background-color: #6699cc;
      background-color: white !important;
    }
    .toggle-switch input[type="checkbox"]:checked + .switch {
      // background-color: #336699;
      background-color: #00b7ae;
    }
  
    &__total-shipment {
      display: flex;
      flex-direction: column;
      align-items: center;
      hr {
        border-top: 1px solid white;
        width: 30% !important;
      }
      &-card {
        width: 60% !important;
        background: white !important;
        border-radius: 12px;
        padding: 10px 15px;
      }
    }
  
    //Custom plus minus
  
    .custom-plus-minus {
      background: white !important;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      .form-control {
        background: none !important;
        border: none !important;
        width: 50px;
        text-align: center;
      }
  
      &__icon {
        // width: 20px;
        // height: 20px;
        border-radius: 50% !important;
        // border: 1px solid #00b7ae !important;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .fa {
          color: #00b7ae !important;
          font-size: 10px !important;
        }
      }
    }
  
    &__input-group {
      display: flex;
      flex-wrap: nowrap;
    }
  
    &__w-219 {
      width: 219px !important;
    }
    &__w-180 {
      width: 180px !important;
    }
    &__w-150 {
      width: 150px !important;
    }
    &__w-100 {
      width: 100px !important;
    }
    &__w-76 {
      width: 76px !important;
    }
    &__w-57 {
      width: 57px !important;
    }
    &__w-72 {
      width: 72px !important;
    }
    &__btn-group {
      height: 38px !important;
      margin-top: 2.5rem;
      .btn-minus {
        height: 17px !important;
        display: flex;
        align-items: center;
      }
    }
  
    .total-cbm {
      &__card {
        background: white !important;
        border-radius: 10px !important;
        color: #404b51;
        font-weight: 600;
  
        a {
          text-decoration: underline !important;
          &:hover {
            color: #00b7ae !important;
            cursor: pointer !important;
          }
        }
      }
    }
  
    &__international-relocation {
      border-right: 1px solid white !important;
    }
    @media (max-width:767px) {
      &__international-relocation {
        border-right: 0px !important;
      }
    }
  }
}
@media (max-width:415px) {
  .sea-freight {
    border-radius: 40px;
    border: 1px solid #404b51 !important;
    background: #404b51;
    max-width: 360px !important;
    min-height: 500px !important;
    background: #404b51 !important;
    padding: 20px 20px 50px 20px !important;
    margin-bottom: 40px !important;
    .custom-input-with-dropdown {
      font-size: 12px !important;
      position: relative;
      .input {
        display: block;
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        padding-right: 10px !important;
  
        &__text {
          white-space: nowrap;
          text-overflow: ellipsis !important;
          width: 70% !important;
          display: flex;
          overflow: hidden;
          font-size: 12px !important;
        }
  
        .fa {
          position: absolute;
          right: 20px !important;
        }
      }
      ul {
        list-style: none;
        background: white;
        padding: 0px !important;
        width: 100%;
        position: absolute;
        z-index: 1;
        border-radius: 6px !important;
        top: 41px;
      }
      li {
        display: flex;
        padding: 10px !important;
        justify-content: start;
        background: white !important;
        align-items: center;
        cursor: pointer;
        border-radius: 6px !important;
        img {
          width: 15px !important;
          height: 20px !important;
        }
      }
      img {
        width: 15px !important;
        height: 20px !important;
      }
    }
  
    .text-transform-none {
      text-transform: none !important;
    }
  
    .input-group-text {
      border: none !important;
      font-size: 10px;
  
      padding-left: 10px;
      padding-right: 10px;
    }
  
    &__input {
      width: auto !important;
    }
  
    &__label {
      color: white;
      font-weight: 600 !important;
      white-space: nowrap;
    }
  
    .toggle-switch {
      position: relative;
      display: inline-block;
      width: 50px;
      height: 25px;
      margin-bottom: 0px !important;
    }
    .toggle-switch input[type="checkbox"] {
      display: none;
    }
    .toggle-switch .switch {
      position: absolute;
      cursor: pointer;
      background-color: #ccc;
      border-radius: 25px;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transition: background-color 0.2s ease;
    }
    .toggle-switch .switch::before {
      position: absolute;
      content: "";
      // left: 2px;
      // top: 2px;
      // width: 21px;
      // height: 21px;
      // background-color: #aaa;
      left: 4px;
      top: 5px;
      width: 15px;
      height: 15px;
      background-color: white;
      border-radius: 50%;
      transition: transform 0.3s ease;
    }
    .toggle-switch input[type="checkbox"]:checked + .switch::before {
      transform: translateX(25px);
      // background-color: #6699cc;
      background-color: white !important;
    }
    .toggle-switch input[type="checkbox"]:checked + .switch {
      // background-color: #336699;
      background-color: #00b7ae;
    }
  
    &__total-shipment {
      display: flex;
      flex-direction: column;
      align-items: center;
      hr {
        border-top: 1px solid white;
        width: 30% !important;
      }
      &-card {
        width: 60% !important;
        background: white !important;
        border-radius: 12px;
        padding: 10px 15px;
      }
    }
  
    //Custom plus minus
  
    .custom-plus-minus {
      background: white !important;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      .form-control {
        background: none !important;
        border: none !important;
        width: 50px;
        text-align: center;
      }
  
      &__icon {
        // width: 20px;
        // height: 20px;
        border-radius: 50% !important;
        // border: 1px solid #00b7ae !important;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .fa {
          color: #00b7ae !important;
          font-size: 10px !important;
        }
      }
    }
  
    &__input-group {
      display: flex;
      flex-wrap: nowrap;
    }
  
    &__w-219 {
      width: 219px !important;
    }
    &__w-180 {
      width: 180px !important;
    }
    &__w-150 {
      width: 150px !important;
    }
    &__w-100 {
      width: 100px !important;
    }
    &__w-76 {
      width: 76px !important;
    }
    &__w-57 {
      width: 57px !important;
    }
    &__w-72 {
      width: 72px !important;
    }
    &__btn-group {
      height: 38px !important;
      margin-top: 2.5rem;
      .btn-minus {
        height: 17px !important;
        display: flex;
        align-items: center;
      }
    }
  
    .total-cbm {
      &__card {
        background: white !important;
        border-radius: 10px !important;
        color: #404b51;
        font-weight: 600;
  
        a {
          text-decoration: underline !important;
          &:hover {
            color: #00b7ae !important;
            cursor: pointer !important;
          }
        }
      }
    }
  
    &__international-relocation {
      border-right: 1px solid white !important;
    }
    @media (max-width:767px) {
      &__international-relocation {
        border-right: 0px !important;
      }
    }
  }
}
@media (max-width:399px) {
  .sea-freight {
    border-radius: 40px;
    border: 1px solid #404b51 !important;
    background: #404b51;
    max-width: 340px !important;
    min-height: 500px !important;
    background: #404b51 !important;
    padding: 20px 20px 50px 20px !important;
    margin-bottom: 40px !important;
    .custom-input-with-dropdown {
      font-size: 12px !important;
      position: relative;
      .input {
        display: block;
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        padding-right: 10px !important;
  
        &__text {
          white-space: nowrap;
          text-overflow: ellipsis !important;
          width: 70% !important;
          display: flex;
          overflow: hidden;
          font-size: 12px !important;
        }
  
        .fa {
          position: absolute;
          right: 20px !important;
        }
      }
      ul {
        list-style: none;
        background: white;
        padding: 0px !important;
        width: 100%;
        position: absolute;
        z-index: 1;
        border-radius: 6px !important;
        top: 41px;
      }
      li {
        display: flex;
        padding: 10px !important;
        justify-content: start;
        background: white !important;
        align-items: center;
        cursor: pointer;
        border-radius: 6px !important;
        img {
          width: 15px !important;
          height: 20px !important;
        }
      }
      img {
        width: 15px !important;
        height: 20px !important;
      }
    }
  
    .text-transform-none {
      text-transform: none !important;
    }
  
    .input-group-text {
      border: none !important;
      font-size: 10px;
  
      padding-left: 10px;
      padding-right: 10px;
    }
  
    &__input {
      width: 240px !important;
    }
  
    &__label {
      color: white;
      font-weight: 600 !important;
      white-space: nowrap;
    }
  
    .toggle-switch {
      position: relative;
      display: inline-block;
      width: 50px;
      height: 25px;
      margin-bottom: 0px !important;
    }
    .toggle-switch input[type="checkbox"] {
      display: none;
    }
    .toggle-switch .switch {
      position: absolute;
      cursor: pointer;
      background-color: #ccc;
      border-radius: 25px;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transition: background-color 0.2s ease;
    }
    .toggle-switch .switch::before {
      position: absolute;
      content: "";
      // left: 2px;
      // top: 2px;
      // width: 21px;
      // height: 21px;
      // background-color: #aaa;
      left: 4px;
      top: 5px;
      width: 15px;
      height: 15px;
      background-color: white;
      border-radius: 50%;
      transition: transform 0.3s ease;
    }
    .toggle-switch input[type="checkbox"]:checked + .switch::before {
      transform: translateX(25px);
      // background-color: #6699cc;
      background-color: white !important;
    }
    .toggle-switch input[type="checkbox"]:checked + .switch {
      // background-color: #336699;
      background-color: #00b7ae;
    }
  
    &__total-shipment {
      display: flex;
      flex-direction: column;
      align-items: center;
      hr {
        border-top: 1px solid white;
        width: 30% !important;
      }
      &-card {
        width: 60% !important;
        background: white !important;
        border-radius: 12px;
        padding: 10px 15px;
      }
    }
  
    //Custom plus minus
  
    .custom-plus-minus {
      background: white !important;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      .form-control {
        background: none !important;
        border: none !important;
        width: 50px;
        text-align: center;
      }
  
      &__icon {
        // width: 20px;
        // height: 20px;
        border-radius: 50% !important;
        // border: 1px solid #00b7ae !important;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .fa {
          color: #00b7ae !important;
          font-size: 10px !important;
        }
      }
    }
  
    &__input-group {
      display: flex;
      flex-wrap: nowrap;
    }
  
    &__w-219 {
      width: 219px !important;
    }
    &__w-180 {
      width: 180px !important;
    }
    &__w-150 {
      width: 150px !important;
    }
    &__w-100 {
      width: 100px !important;
    }
    &__w-76 {
      width: 76px !important;
    }
    &__w-57 {
      width: 57px !important;
    }
    &__w-72 {
      width: 72px !important;
    }
    &__btn-group {
      height: 38px !important;
      margin-top: 2.5rem;
      .btn-minus {
        height: 17px !important;
        display: flex;
        align-items: center;
      }
    }
  
    .total-cbm {
      &__card {
        background: white !important;
        border-radius: 10px !important;
        color: #404b51;
        font-weight: 600;
  
        a {
          text-decoration: underline !important;
          &:hover {
            color: #00b7ae !important;
            cursor: pointer !important;
          }
        }
      }
    }
  
    &__international-relocation {
      border-right: 1px solid white !important;
    }
    @media (max-width:767px) {
      &__international-relocation {
        border-right: 0px !important;
      }
    }
  }
}
@media (max-width:377px) {
  .sea-freight {
    border-radius: 40px;
    border: 1px solid #404b51 !important;
    background: #404b51;
    max-width: 330px !important;
    min-height: 500px !important;
    background: #404b51 !important;
    padding: 20px 20px 50px 20px !important;
    margin-bottom: 40px !important;
    .custom-input-with-dropdown {
      font-size: 12px !important;
      position: relative;
      .input {
        display: block;
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        padding-right: 10px !important;
  
        &__text {
          white-space: nowrap;
          text-overflow: ellipsis !important;
          width: 70% !important;
          display: flex;
          overflow: hidden;
          font-size: 12px !important;
        }
  
        .fa {
          position: absolute;
          right: 20px !important;
        }
      }
      ul {
        list-style: none;
        background: white;
        padding: 0px !important;
        width: 100%;
        position: absolute;
        z-index: 1;
        border-radius: 6px !important;
        top: 41px;
      }
      li {
        display: flex;
        padding: 10px !important;
        justify-content: start;
        background: white !important;
        align-items: center;
        cursor: pointer;
        border-radius: 6px !important;
        img {
          width: 15px !important;
          height: 20px !important;
        }
      }
      img {
        width: 15px !important;
        height: 20px !important;
      }
    }
  
    .text-transform-none {
      text-transform: none !important;
    }
  
    .input-group-text {
      border: none !important;
      font-size: 10px;
  
      padding-left: 10px;
      padding-right: 10px;
    }
  
    &__input {
      width: 235px !important;
    }
  
    &__label {
      color: white;
      font-weight: 600 !important;
      white-space: nowrap;
      font-size: 15px;
    }
  
    .toggle-switch {
      position: relative;
      display: inline-block;
      width: 50px;
      height: 25px;
      margin-bottom: 0px !important;
    }
    .toggle-switch input[type="checkbox"] {
      display: none;
    }
    .toggle-switch .switch {
      position: absolute;
      cursor: pointer;
      background-color: #ccc;
      border-radius: 25px;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transition: background-color 0.2s ease;
    }
    .toggle-switch .switch::before {
      position: absolute;
      content: "";
      // left: 2px;
      // top: 2px;
      // width: 21px;
      // height: 21px;
      // background-color: #aaa;
      left: 4px;
      top: 5px;
      width: 15px;
      height: 15px;
      background-color: white;
      border-radius: 50%;
      transition: transform 0.3s ease;
    }
    .toggle-switch input[type="checkbox"]:checked + .switch::before {
      transform: translateX(25px);
      // background-color: #6699cc;
      background-color: white !important;
    }
    .toggle-switch input[type="checkbox"]:checked + .switch {
      // background-color: #336699;
      background-color: #00b7ae;
    }
  
    &__total-shipment {
      display: flex;
      flex-direction: column;
      align-items: center;
      hr {
        border-top: 1px solid white;
        width: 30% !important;
      }
      &-card {
        width: 60% !important;
        background: white !important;
        border-radius: 12px;
        padding: 10px 15px;
      }
    }
  
    //Custom plus minus
  
    .custom-plus-minus {
      background: white !important;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      .form-control {
        background: none !important;
        border: none !important;
        width: 50px;
        text-align: center;
      }
  
      &__icon {
        // width: 20px;
        // height: 20px;
        border-radius: 50% !important;
        // border: 1px solid #00b7ae !important;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .fa {
          color: #00b7ae !important;
          font-size: 10px !important;
        }
      }
    }
  
    &__input-group {
      display: flex;
      flex-wrap: nowrap;
    }
  
    &__w-219 {
      width: 219px !important;
    }
    &__w-180 {
      width: 180px !important;
    }
    &__w-150 {
      width: 150px !important;
    }
    &__w-100 {
      width: 100px !important;
    }
    &__w-76 {
      width: 76px !important;
    }
    &__w-57 {
      width: 57px !important;
    }
    &__w-72 {
      width: 72px !important;
    }
    &__btn-group {
      height: 38px !important;
      margin-top: 2.5rem;
      .btn-minus {
        height: 17px !important;
        display: flex;
        align-items: center;
      }
    }
  
    .total-cbm {
      &__card {
        background: white !important;
        border-radius: 10px !important;
        color: #404b51;
        font-weight: 600;
  
        a {
          text-decoration: underline !important;
          &:hover {
            color: #00b7ae !important;
            cursor: pointer !important;
          }
        }
      }
    }
  
    &__international-relocation {
      border-right: 1px solid white !important;
    }
    @media (max-width:767px) {
      &__international-relocation {
        border-right: 0px !important;
      }
    }
  }
}
@media (max-width:365px) {
  .sea-freight {
    border-radius: 40px;
    border: 1px solid #404b51 !important;
    background: #404b51;
    max-width: 310px !important;
    min-height: 500px !important;
    background: #404b51 !important;
    padding: 20px 20px 50px 20px !important;
    margin-bottom: 40px !important;
    .custom-input-with-dropdown {
      font-size: 12px !important;
      position: relative;
      .input {
        display: block;
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        padding-right: 10px !important;
  
        &__text {
          white-space: nowrap;
          text-overflow: ellipsis !important;
          width: 70% !important;
          display: flex;
          overflow: hidden;
          font-size: 12px !important;
        }
  
        .fa {
          position: absolute;
          right: 20px !important;
        }
      }
      ul {
        list-style: none;
        background: white;
        padding: 0px !important;
        width: 100%;
        position: absolute;
        z-index: 1;
        border-radius: 6px !important;
        top: 41px;
      }
      li {
        display: flex;
        padding: 10px !important;
        justify-content: start;
        background: white !important;
        align-items: center;
        cursor: pointer;
        border-radius: 6px !important;
        img {
          width: 15px !important;
          height: 20px !important;
        }
      }
      img {
        width: 15px !important;
        height: 20px !important;
      }
    }
  
    .text-transform-none {
      text-transform: none !important;
    }
  
    .input-group-text {
      border: none !important;
      font-size: 10px;
  
      padding-left: 10px;
      padding-right: 10px;
    }
  
    &__input {
      width: 212px !important;
    }
  
    &__label {
      color: white;
      font-weight: 600 !important;
      white-space: nowrap;
      font-size: 14px;
    }
  
    .toggle-switch {
      position: relative;
      display: inline-block;
      width: 50px;
      height: 25px;
      margin-bottom: 0px !important;
    }
    .toggle-switch input[type="checkbox"] {
      display: none;
    }
    .toggle-switch .switch {
      position: absolute;
      cursor: pointer;
      background-color: #ccc;
      border-radius: 25px;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transition: background-color 0.2s ease;
    }
    .toggle-switch .switch::before {
      position: absolute;
      content: "";
      // left: 2px;
      // top: 2px;
      // width: 21px;
      // height: 21px;
      // background-color: #aaa;
      left: 4px;
      top: 5px;
      width: 15px;
      height: 15px;
      background-color: white;
      border-radius: 50%;
      transition: transform 0.3s ease;
    }
    .toggle-switch input[type="checkbox"]:checked + .switch::before {
      transform: translateX(25px);
      // background-color: #6699cc;
      background-color: white !important;
    }
    .toggle-switch input[type="checkbox"]:checked + .switch {
      // background-color: #336699;
      background-color: #00b7ae;
    }
  
    &__total-shipment {
      display: flex;
      flex-direction: column;
      align-items: center;
      hr {
        border-top: 1px solid white;
        width: 30% !important;
      }
      &-card {
        width: 60% !important;
        background: white !important;
        border-radius: 12px;
        padding: 10px 15px;
      }
    }
  
    //Custom plus minus
  
    .custom-plus-minus {
      background: white !important;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      .form-control {
        background: none !important;
        border: none !important;
        width: 50px;
        text-align: center;
      }
  
      &__icon {
        // width: 20px;
        // height: 20px;
        border-radius: 50% !important;
        // border: 1px solid #00b7ae !important;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .fa {
          color: #00b7ae !important;
          font-size: 10px !important;
        }
      }
    }
  
    &__input-group {
      display: flex;
      flex-wrap: nowrap;
    }
  
    &__w-219 {
      width: 219px !important;
    }
    &__w-180 {
      width: 180px !important;
    }
    &__w-150 {
      width: 150px !important;
    }
    &__w-100 {
      width: 100px !important;
    }
    &__w-76 {
      width: 76px !important;
    }
    &__w-57 {
      width: 57px !important;
    }
    &__w-72 {
      width: 72px !important;
    }
    &__btn-group {
      height: 38px !important;
      margin-top: 2.5rem;
      .btn-minus {
        height: 17px !important;
        display: flex;
        align-items: center;
      }
    }
  
    .total-cbm {
      &__card {
        background: white !important;
        border-radius: 10px !important;
        color: #404b51;
        font-weight: 600;
  
        a {
          text-decoration: underline !important;
          &:hover {
            color: #00b7ae !important;
            cursor: pointer !important;
          }
        }
      }
    }
  
    &__international-relocation {
      border-right: 1px solid white !important;
    }
    @media (max-width:767px) {
      &__international-relocation {
        border-right: 0px !important;
      }
    }
  }
}
@media (max-width:340px) {
  .sea-freight {
    max-width: 290px !important;
    &__input {
      width: 195px !important;
    }
    &__label {
      color: white;
      font-weight: 600 !important;
      white-space: nowrap;
      font-size: 13px;
    }
  }
}
@media (max-width:320px) {
  .sea-freight {
    border-radius: 40px;
    border: 1px solid #404b51 !important;
    background: #404b51;
    max-width: 280px !important;
    min-height: 500px !important;
    background: #404b51 !important;
    padding: 20px 20px 50px 20px !important;
    margin-bottom: 40px !important;
    .custom-input-with-dropdown {
      font-size: 12px !important;
      position: relative;
      .input {
        display: block;
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        padding-right: 10px !important;
  
        &__text {
          white-space: nowrap;
          text-overflow: ellipsis !important;
          width: 70% !important;
          display: flex;
          overflow: hidden;
          font-size: 12px !important;
        }
  
        .fa {
          position: absolute;
          right: 20px !important;
        }
      }
      ul {
        list-style: none;
        background: white;
        padding: 0px !important;
        width: 100%;
        position: absolute;
        z-index: 1;
        border-radius: 6px !important;
        top: 41px;
      }
      li {
        display: flex;
        padding: 10px !important;
        justify-content: start;
        background: white !important;
        align-items: center;
        cursor: pointer;
        border-radius: 6px !important;
        img {
          width: 15px !important;
          height: 20px !important;
        }
      }
      img {
        width: 15px !important;
        height: 20px !important;
      }
    }
  
    .text-transform-none {
      text-transform: none !important;
    }
  
    .input-group-text {
      border: none !important;
      font-size: 10px;
  
      padding-left: 10px;
      padding-right: 10px;
    }
  
    &__input {
      width: 180px !important;
    }
  
    &__label {
      font-size: 12px;
      color: white;
      font-weight: 600 !important;
      white-space: nowrap;
    }
  
    .toggle-switch {
      position: relative;
      display: inline-block;
      width: 50px;
      height: 25px;
      margin-bottom: 0px !important;
    }
    .toggle-switch input[type="checkbox"] {
      display: none;
    }
    .toggle-switch .switch {
      position: absolute;
      cursor: pointer;
      background-color: #ccc;
      border-radius: 25px;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transition: background-color 0.2s ease;
    }
    .toggle-switch .switch::before {
      position: absolute;
      content: "";
      // left: 2px;
      // top: 2px;
      // width: 21px;
      // height: 21px;
      // background-color: #aaa;
      left: 4px;
      top: 5px;
      width: 15px;
      height: 15px;
      background-color: white;
      border-radius: 50%;
      transition: transform 0.3s ease;
    }
    .toggle-switch input[type="checkbox"]:checked + .switch::before {
      transform: translateX(25px);
      // background-color: #6699cc;
      background-color: white !important;
    }
    .toggle-switch input[type="checkbox"]:checked + .switch {
      // background-color: #336699;
      background-color: #00b7ae;
    }
  
    &__total-shipment {
      display: flex;
      flex-direction: column;
      align-items: center;
      hr {
        border-top: 1px solid white;
        width: 30% !important;
      }
      &-card {
        width: 60% !important;
        background: white !important;
        border-radius: 12px;
        padding: 10px 15px;
      }
    }
  
    //Custom plus minus
  
    .custom-plus-minus {
      background: white !important;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      .form-control {
        background: none !important;
        border: none !important;
        width: 50px;
        text-align: center;
      }
  
      &__icon {
        // width: 20px;
        // height: 20px;
        border-radius: 50% !important;
        // border: 1px solid #00b7ae !important;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .fa {
          color: #00b7ae !important;
          font-size: 10px !important;
        }
      }
    }
  
    &__input-group {
      display: flex;
      flex-wrap: nowrap;
    }
  
    &__w-219 {
      width: 219px !important;
    }
    &__w-180 {
      width: 180px !important;
    }
    &__w-150 {
      width: 150px !important;
    }
    &__w-100 {
      width: 100px !important;
    }
    &__w-76 {
      width: 76px !important;
    }
    &__w-57 {
      width: 57px !important;
    }
    &__w-72 {
      width: 72px !important;
    }
    &__btn-group {
      height: 38px !important;
      margin-top: 2.5rem;
      .btn-minus {
        height: 17px !important;
        display: flex;
        align-items: center;
      }
    }
  
    .total-cbm {
      &__card {
        background: white !important;
        border-radius: 10px !important;
        color: #404b51;
        font-weight: 600;
  
        a {
          text-decoration: underline !important;
          &:hover {
            color: #00b7ae !important;
            cursor: pointer !important;
          }
        }
      }
    }
  
    &__international-relocation {
      border-right: 1px solid white !important;
    }
    &__international-relocation Input::placeholder{
      font-size: 14px;
    }
    @media (max-width:767px) {
      &__international-relocation {
        border-right: 0px !important;
      }
    }
  }
}
@media (max-width:310px) {
  .sea-freight {
    max-width: 270px !important;
    &__input {
      width: 170px !important;
    }
    &__label {
      color: white;
      font-weight: 600 !important;
      white-space: nowrap;
      font-size: 11px;
    }
  }
}
@media (max-width:300px) {
  .sea-freight {
    max-width: 260px !important;
    &__input {
      width: 165px !important;
    }
    &__label {
      color: white;
      font-weight: 600 !important;
      white-space: nowrap;
      font-size: 10.5px;
    }
  }
}
@media (max-width:285px) {
  .sea-freight {
    max-width: 240px !important;
    &__input {
      width: 150px !important;
    }
  }
}

@media (max-width:767px) {
  .freestandingstyle{
    margin-top: 0.5rem;
  }
}
@media (max-width:320px) {
  .freestandingstyle {
    font-size: 14px;
  }
}
@media (max-width:767px) {
  .seafrightlabel{
    margin-top: 10px !important;
  }
}
@media (max-width:320px) {
  .contact_details Input::placeholder{
    font-size: 14px;
  }
}
@media (max-width:900px) {
  // .movingboxtable{
  //   display: flex;
  //   flex-direction: column !important;
  // }
  .movingboxtable Input{
    font-size: 14px;
    // width: 100% !important;
  }
  .movingboxtable .form-control{
    font-size: 14px;
    // width: 100% !important;
  }
}
@media (max-width:870px) {
  .movingboxtable{
    display: flex;
    flex-direction: column !important;
  }
  .movingboxtable Input{
    font-size: 14px;
    width: 100% !important;
  }
  .movingboxtable .form-control{
    font-size: 14px;
    width: 100% !important;
  }
  .movingboxinput{
    margin-top: 5px;
    width: 100% !important;
  }
}
@media (max-width:530px) {
  .fclcontainer{
    margin-left: 10px;
    display: flex;
    flex-direction: column;
  }
  .fclcontainer .fclbox{
    margin-top: 5px;
    width: 100% !important;
  }
}
@media (max-width:530px) {
  .sea-freight .custom-plus-minus .form-control{
    width: 100% !important;
  }
}
@media (max-width:767px) {
  .seacollectdate{
    width: 100% !important;
  }
}